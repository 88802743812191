.fc .fc-toolbar-title {
  font-size: 1.5em !important;
}

.fc .fc-button-primary {
  text-transform: capitalize !important;
}

.fc .fc-button-primary:disabled {
  text-transform: capitalize !important;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #f5f5f5 !important;
}

.fc .fc-daygrid-event {
  cursor: pointer !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 5px !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 5px !important;
}

