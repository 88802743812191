.orgchart-container {
  background-color: #f4f4f4;
}

.orgchart .oc-node .oc-edge {
  display: none;
}

.orgchart ul li .oc-node:hover {
  background-color: transparent !important;
}

.orgchart ul li .oc-node.selected {
  background-color: transparent !important;
}

.orgchart {
  background-image: none;
  padding: 40px 20px 0px 20px;
}

.oci-leader {
  display: none;
}

.orgchart > ul > li > ul li::before {
  border-top: 2px solid #9e9e9e;
}

.orgchart > ul > li > ul li > .oc-node::before,
.orgchart ul li .oc-node:not(:only-child)::after {
  background-color: #9e9e9e;
}

.orgchart ul li .oc-node .oc-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-top: 5px solid #2F74EB;
  background-color: #fff;
  color: #222f3e;
  font-size: 0.9rem;
  width: 280px;
  height: 2.5rem;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px 5px 0 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.orgchart ul li .oc-node .project-assign {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;
  border-top: none;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-radius: 0 0 5px 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 16rem;
  color: #222f3e;
  background-color: #fff;
  font-size: 0.9rem;
  padding: 10px;
  white-space: inherit;
}
