.approved_outlined {
  margin: 10px !important;
  color: #4caf50 !important;
  border: 2px solid #4caf50 !important;
  border-radius: 50px !important;
  cursor: pointer !important;
}

.approved_contained,
.approved_outlined:hover {
  margin: 10px !important;
  color: #fff !important;
  background-color: #4caf50 !important;
  border-radius: 50px !important;
  cursor: pointer !important;
}

.notApproved_outlined {
  margin: 10px !important;
  color: #f44336 !important;
  border: 2px solid #f44336 !important;
  border-radius: 50px !important;
  cursor: pointer !important;
}

.notApproved_contained,
.notApproved_outlined:hover {
  margin: 10px !important;
  color: #fff !important;
  background-color: #f44336 !important;
  border-radius: 50px !important;
  cursor: pointer !important;
}
