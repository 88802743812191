@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ant {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translateX(-2.61814px) translateY(-5.23629px) translateZ(0px)
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes ant2 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translateX(3.99835px) translateY(7.99671px) translateZ(0px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.wrapCircle3D {
  animation: rotating 4s linear infinite;
}

.wrapCoin13D,
.wrapLight,
.wrapTimer,
.wrapCalendar1 {
  animation: ant 3.5s ease-in-out 0s infinite normal;
}

.wrapCloud1,
.wrapCloud2,
.wrapCheckList,
.wrapCalendar {
  animation: ant2 3.5s ease-in-out 0s infinite normal;
}

.container {
  padding: 40px;
  background: #419be0;
}

.slick-list {
  padding: 0px 0px !important;
}

.slick-slide > div > div > .wrap-avatar > .MuiAvatar-root {
  opacity: 0.5;
  width: 48px;
  height: 48px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.slick-center > div > div > .wrap-avatar > .MuiAvatar-root {
  opacity: 1;
  transform: scale(2);
}
