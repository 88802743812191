.moonShot {
  font-size: 8rem;
  color: #9e9e9e;
  cursor: pointer;
  transition: color 300ms;
}

.moonShot_Selected {
  font-size: 8rem;
  color: #ff9933;
  cursor: pointer;
  transition: #ff9933 300ms;
}

input[type="radio"] {
  display: none;
}
