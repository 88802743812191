.pac-container {
  padding: 5px;
  border-radius: 0px 0px 10px 10px;
  border-top: none;
  box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
  z-index: 2000;
}

.pac-item {
  border-top: none;
  padding: 5px;
}

#pac-input {
  background-color: #fff;
  font-family: Roboto;
  font-size: 16px;
  padding-left: 15px;
  text-overflow: ellipsis;
}

#pac-input:focus {
  border-color: #4d90fe;
}

#title {
  color: #fff;
  background-color: #4d90fe;
  font-size: 25px;
  font-weight: 500;
  padding: 6px 12px;
}

#target {
  width: 345px;
}
