.info-img-card {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
    border-right: 1px solid #eeeeee;
  }
  
  .img-person {
    border-radius: 50%;
  }
  
  .score-section {
    margin-top: 40px;
    padding-bottom: 40px;
  }
  
  .scoreCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px solid #4f65df;
    font-weight: 700;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
  }
  
  @media screen and (min-width: 320px) and (max-width: 480px) {
    .info-img-card {
      border-right: 0px;
    }
  }
  