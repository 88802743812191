.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.page {
  padding-top: 80px;
  width: 100%;
  background-color: #f1f4f9;
  min-height: calc(100vh - 80px);
}

.dashboard-page {
  background-color: #0d1117;
  color: #c9d1d9;
}

/* .MuiTypography-root {
  color: #212b36;
} */

.myButton {
  background-color: #ff8400;
  border-radius: 28px;
  border: 1px solid #e87605;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
  height: auto;
  padding: 5px 17px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #ff8400;
}
.myButton:hover {
  background-color: #fa901e;
  border-radius: 28px;
}
.myButton:active {
  position: relative;
  top: 1px;
}
.myButton:focus {
  outline: none;
}

.excelButton {
  background-color: #188f38;
  border-radius: 28px;
  border: 1px solid #18ab29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
  padding: 5px 17px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627;
}
.excelButton:hover {
  background-color: #419e0f;
}
.excelButton:active {
  position: relative;
  top: 1px;
}
.excelButton:focus {
  outline: none;
}

.rbc-header {
  padding: 8px 3px !important;
}

.rbc-off-range-bg {
  background-color: #d2d2d20d !important;
}

.rbc-today {
  background-color: #46cbe2 !important;
}

.rbc-now {
  color: #ffffff !important;
}

.rbc-date-cell:first-child,
.rbc-date-cell:last-child {
  color: #e56a75;
}

.rbc-month-view {
  border-color: #919eab3d !important;
}

.holidayEvent {
  background-color: #7451ff !important;
  color: #ffffff !important;
}
.otEvent {
  background-color: #1e4db6 !important;
  color: #ffffff !important;
}
.invalidTimeEvent {
  background-color: #38ca7f !important;
  color: #ffffff !important;
}
.absentEvent {
  background-color: #1997f5 !important;
  color: #ffffff !important;
}
.lateEvent {
  background-color: #fc4b6c !important;
  color: #ffffff !important;
}
.leaveEvent {
  background-color: #fdc90c !important;
  color: #ffffff !important;
}

/* Card Component Styles */
.card-section {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  width: 100%;
  border-radius: 15px;
  background-color: #fff;
}

.header-section {
  background: rgb(79, 101, 223);
  background: linear-gradient(
    90deg,
    rgba(79, 101, 223, 1) 0%,
    rgba(69, 91, 215, 1) 100%
  );
  border-radius: 15px 15px 0px 0px;
  margin-bottom: 30px;
}

.header-topic {
  font-weight: 700;
  margin: 25px;
  color: #fff;
  font-size: 1.5rem;
}

.saveDraft,
.saveDraft:hover {
  border-radius: 30px !important;
  height: 50px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  color: #4f65df !important;
  border: 3px solid #4f65df !important;
}

.saveButton {
  border-radius: 30px !important;
  height: 50px !important;
  background-color: #4f65df !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.hrTag {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  border: 1px solid #eeeeee;
}

.svgKey {
  padding-right: 10px;
}

.keyResult {
  display: flex;
  align-items: center;
  font-weight: 700 !important;
}

.sliderStyle {
  margin-top: 15px;
}

.submitGroup {
  padding-top: 64px;
  /*padding-bottom: 64px;*/
}

.cardPlan_Objective {
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 16px;
  min-height: 80px;
  background-color: #fff;
  border-top: 8px solid #4f65df;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 2px 0px;
}

.cardPlan {
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 15px;
  padding: 16px;
  min-height: 80px;
  background-color: #ffffff;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 2px 0px;
}

input[type='file']::file-selector-button {
  cursor: pointer;
  border: 2px solid #bdbdbd;
  padding: 0.5em 0.5em;
  border-radius: 0.5em;
  background-color: #eeeeee;
  transition: 1s;
  color: #555;
}

input[type='file']::file-selector-button:hover {
  background-color: #e0e0e0;
  border: 2px solid #bdbdbd;
}

.styles-module_wrapper__1I_qj {
  padding-top: 70px !important;
}

.styles-module_close__2I1sI {
  position: absolute;
  top: 70px !important;
  right: 20px !important;
}

*::-webkit-scrollbar {
  width: "0px";
}
*::-webkit-scrollbar-track {
  background-color: darkgrey;
}
*::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
}
