.orgchart-container {
  background-color: #f4f4f4;
  border: 1px solid #dee2e6;
  padding-top: 40px;
  min-height: 500px;
}

.orgchart.myChart {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: none !important;
}

@media screen and (min-width: 320px) and (max-width: 620px) {
  .orgchart.myChart {
    justify-content: flex-start;
  }
}

.orgchart ul li .oc-node .oc-heading .oc-symbol::before,
.orgchart ul li .oc-node .oc-heading .oc-symbol::after {
  display: none;
}

.orgchart ul li .oc-node .oc-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-top: 5px solid #795548;
  background-color: #d7ccc8;
  color: #222f3e;
  font-size: 1rem;
  width: 15rem;
  height: 3rem;
  border-radius: 5px 5px 0 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#organization .oc-heading {
  border-top: 5px solid #e53935;
  background-color: #ffbfbd;
}

div[id^="T"] .oc-heading {
  border-top: 5px solid #4f65df !important;
  background-color: #d7ddff !important;
}

div[id^="M"] .oc-heading {
  border-top: 5px solid #ff5722 !important;
  background-color: #ffccbc !important;
}

div[id^="A"] .oc-heading {
  border-top: 5px solid #ffc107 !important;
  background-color: #ffecb3 !important;
}

div[id^="O"] .oc-heading {
  border-top: 5px solid #4caf50 !important;
  background-color: #c8e6c9 !important;
}

.orgchart ul li .oc-node .oc-content {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-top: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0 0 5px 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 15rem;
  height: 4rem;
  color: #222f3e;
  font-size: 0.9rem;
  padding: 10px;
  white-space: inherit;
}

.orgchart ul li .oc-node .oc-okrs {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-top: none;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 0 0 5px 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 15rem;
  height: 3rem;
  color: #222f3e;
  background-color: #fff;
  font-size: 0.9rem;
  padding: 10px;
  white-space: inherit;
}

.orgchart ul li .oc-node:hover,
.orgchart ul li .oc-node.selected {
  background-color: #dee2e6;
  border-radius: 10px;
}

.orgchart.myChart > ul > li > ul li::before {
  border-top-color: #215a88;
}

.orgchart.myChart > ul > li > ul li .oc-node::before,
.orgchart.myChart ul li .oc-node:not(:only-child)::after {
  background-color: #215a88;
}
